@charset "utf-8";

$navbar-item-hover-background-color: none;
$navbar-background-color: initial;//#ff6c8a;
$light-text-color: #ffebee;
$navbar-item-color: #fff;
$navbar-item-hover-color: $light-text-color;
$navbar-box-shadow-size: none;
$navbar-bottom-box-shadow-size: none;

$light-black: #313131;

// @import "../node_modules/bulma/bulma.sass";
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/base/minireset.sass";
@import "bulma/sass/base/generic.sass";
@import "bulma/sass/components/navbar.sass";
@import url(https://fonts.googleapis.com/css2?family=Fredoka+One);

$fredoka-one: 'Fredoka One', 'sans-serif';

html {
  scroll-behavior: smooth;
}

.App {
}

.Hero {
  // background-color: #ff6c8a;
  // padding: 4rem 10rem;
  color: #fff;
  // background:
  //   linear-gradient(rgba(255, 108, 138, 0.9), rgba(255, 108, 138, 0.9));
}

.Hero-content-container {
  padding: 0 4rem;
  display: grid;
  place-items: center center;
}

.Hero-content {
  display: grid;
  place-items: center center;
  grid-template-columns: 1fr 1.3fr;
  max-width: 1216px;
  column-gap: 2rem;
  padding: 2rem 0;
}

@media screen and (max-width: 1024px) {
  .Hero-content-container {
    padding: 0;
  }
  .Hero-content {
    grid-template-columns: 1fr;
    row-gap: 1em;
    max-width: 768px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.Hero-headline {
  font-size: 3rem;
  font-weight: 300;
  font-family: $fredoka-one;
  // text-shadow: 0px 0px 15px rgba(35, 37, 38, 0.2);
}

.Hero-subline {
  font-size: 1.5rem;
  color: #fff2f4;//$light-text-color;
  // text-shadow: 0px 0px 10px rgba(35, 37, 38, 0.2);
}


@media screen and (max-width: 1024px) {
  .Hero-header {
    max-width: 614px;
  }
  .Hero-headline {
    font-size: 2.7rem;
  }
  .Hero-subline {
    font-size: 1.5rem;
  }
}

.Hero-product {
  width: 100%;
}

.Hero-header .add-to-slack {
  display: inline-block;
  padding-top: 1.5rem;
}

.Hero-header .add-to-slack .subtext {
  font-size: 0.9rem;
  text-align: center;
}


.Hero-header .add-to-slack img {
  border-radius: 8px;
}

.bg-pattern {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background:
    // linear-gradient(to top, rgba(255, 108, 138, 0.9), rgba(255, 108, 138, 0.9) 20%, rgba(255, 108, 138, 0.9) 100%),
    linear-gradient(to bottom, rgba(194, 108, 255, 0.97), rgba(255, 108, 138, 0.90) 100%),
    url('./media/pattern.png') repeat;
  z-index: -1;
}

.wave svg {
  display: block;
}

.Features-container .Features:first-child .Features-content{
  padding-top: 0;
}

.Features-container .Features:last-child .Features-content {
  padding-bottom: 0;
}

// .Features:first-child .Features-content {
//   padding-top: 0 !important;
// }

// .Features:last-child .Features-content {
//   padding-bottom: 0 !important;
// }

.Features {
  background-color: #fff;
}

.Features-content-container {
  display: grid;
  place-items: center center;
  padding: 0 2rem;
}

.Features-content {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  max-width: 1216px;
  column-gap: 2rem;
  padding: 10rem 0;
  place-items: center center;
}


@media screen and (max-width: 1024px) {
  .Features-content {
    grid-template-columns: 1fr;
    row-gap: 1em;
    max-width: 768px;
    // padding-left: 1.5rem;
    // padding-right: 1.5rem;
  }
  .Features-header {
    max-width: 512px;
  }
}

.Features-header {
}

.Features-header-image {
  max-width: 150px;
}

.Features-headline {
  font-family: $fredoka-one;
  font-size: 2.5rem;
}

.Features-subline {
  font-size: 1.5rem;
}

.Features-one {

}

footer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 5rem 10rem;
  background-color: #fff;
}

footer a {
  color: $text;
}

@media screen and (max-width: 1024px) {
  footer {
    padding: 5rem 2rem;
    grid-template-columns: 1fr;
    row-gap: 1.2rem;
  }
}

footer .company {
  justify-content: flex-start;
}

footer .company .copyright {
  font-size: 0.9rem;
}

footer .company img.logo {  
  height: 3rem;
}

footer .Footer-content {
  display: flex;
  flex-direction: row;
}

footer .Footer-section {
  flex: 1;
}


@media screen and (max-width: 768px) {
  footer .Footer-content {
    flex-direction: column;
  }

  footer .Footer-section:not(:last-child) {
    padding-bottom: 1rem;
  }
}

footer .Footer-content .Footer-section:not(:first-child) {
  // padding-left: 3rem;
}

footer .Footer-section-header {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
}

footer .Footer-section-about {
  flex: 2;
}

.navbar {
  padding-top: 8px;
}
@media screen and (min-width: 1024px) {
  .navbar {
    padding: 0 120px;
    padding-top: 25px;
  }
}

// .navbar {
//   background: linear-gradient(rgba(255, 108, 138, 0.9), rgba(255, 108, 138, 0.9)) !important;
// }

.navbar-burger:hover {
  background-color: initial !important;
}

.navbar-item img {
  max-height: initial !important;
}
a.navbar-item:hover {
  background-color: none !important;
}
.nav-logo {
  // width: 542px;
  height: 50px;
}
